import React from "react";
import Layout from "../components/layout";
import styled from "@emotion/styled";
import accommodationHeaderImage from "../assets/images/accommodation-header.jpg";
import accommodationSidebar01 from "../assets/images/activities-pic-04.jpg";
import accommodationSidebar02 from "../assets/images/activities-pic-02.jpg";

const PageHeader = styled.div`
  background: url(${accommodationHeaderImage});
  background-size: cover;
  background-position: center center;
  color: black;
  padding: 20px;
  padding-left: 0;
  min-height: 250px;
  border-bottom: solid 2px black;
  margin-bottom: 30px;
`;

const TestimonialContainer = styled.div`
  font-size: 0.9rem;

  > div:nth-of-type(odd) {
    background-color: #f2f4f5;
    padding: 20px;
  }

  > div:nth-of-type(even) {
    background-color: #e0f3fd;
    padding: 20px;
  }
`;

const Testimonial = styled("div")`
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;

  p {
  }

  span {
    display: block;
    padding-top: 10px;
    font-weight: bold;
  }
`;

export default () => {
  return (
    <Layout>
      <PageHeader>
        <h1>
          <span className="animated fadeInLeft">Accommodation</span>
        </h1>
      </PageHeader>
      <div
        className="sub-page main-content"
        style={{
          minHeight: "300px",
          display: "grid",
          gridGap: "3rem",
          gridTemplateColumns: "3fr 1fr",
        }}
      >
        <div>
          <p>
            Our rooms single and double all have WC access with some en-suites.
            TV and radio are available throughout. We have recently added seven
            beautiful en-suite rooms, all of which have TV, WiFi and telephone
            access if required.
          </p>
          <p>
            The rooms are finished to an exceptionally high standard with
            spacious storage for personal belongings to help us ensure residents
            truly feel at home in their new surroundings.
          </p>
          <p>
            Our sitting & dining rooms are easy to access and the unit's fine
            long corridors allow for exercise and walks on rainy days, to keep
            residents fit and active.
          </p>
        </div>
        <div className="sidebar">
          <img src={accommodationSidebar01} alt="" />
          <img src={accommodationSidebar02} alt="" />
        </div>
      </div>
    </Layout>
  );
};
